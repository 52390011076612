import React from 'react';

const ProblemSection = () => {
  return (
    <div className="relative">
      <div className="text-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-5xl font-bold mb-6">
            ... We Also Help You <span className="text-purple-600">Get Actual Results Post-Hire,</span><br />
            And <span className="text-purple-600">10x Their Performance With Our AI Platform!</span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default ProblemSection;