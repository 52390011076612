import React from 'react';

const ExpertsBanner = () => {
  const experts = [
    { name: 'BP', logo: `${process.env.PUBLIC_URL}/Logos/BP.png` },
    { name: 'Google', logo: `${process.env.PUBLIC_URL}/Logos/Google.jpg` },
    { name: 'IBM', logo: `${process.env.PUBLIC_URL}/Logos/IBM.png` },
    { name: 'Lego', logo: `${process.env.PUBLIC_URL}/Logos/Lego.png` },
    { name: 'Maersk', logo: `${process.env.PUBLIC_URL}/Logos/Maersk.png` },
    { name: 'MC', logo: `${process.env.PUBLIC_URL}/Logos/MC.svg` },
    { name: 'Microsoft', logo: `${process.env.PUBLIC_URL}/Logos/Microsoft.jpg` },
  ];

  return (
    <div className="bg-white py-6 overflow-hidden w-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center">
          <p className="text-lg font-medium text-gray-600 mr-8 whitespace-nowrap flex-shrink-0">
            With Experts From:
          </p>
          <div className="logo-scroll-container flex-grow overflow-hidden">
            <div className="logo-scroll flex">
              {[...experts, ...experts].map((expert, index) => (
                <img
                  key={`${expert.name}-${index}`}
                  src={expert.logo}
                  alt={`${expert.name} logo`}
                  className="h-16 w-32 object-contain object-center mx-6"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertsBanner;
