import React from 'react';
import Lottie from 'lottie-react';
import animationData from './DeadAvatarJSON.json';

const DeadAvatar = () => {
  return (
    <div className="bg-purple-50 px-4 py-16 sm:py-24 md:py-32">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-8 sm:mb-12">
          Do You Want To End Up <span className="text-purple-600">Like This?</span>
        </h2>
        <p className="text-2xl sm:text-3xl md:text-4xl text-gray-600 mb-12 sm:mb-16">
          Did You Know: 🤔 Most Agencies Forget About You Once You've Hired Their Developers?
        </p>
        
        <div className="w-full max-w-4xl mx-auto mb-12 sm:mb-16">
          <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
        
        <div className="mt-12 sm:mt-16">
          <img 
            src={`${process.env.PUBLIC_URL}/DeadAvatar/Fact.png`} 
            alt="Fact" 
            className="w-full max-w-3xl mx-auto h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default DeadAvatar;