import React from 'react';

const TalentIntroSection = () => {
  return (
    <div className="text-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-4xl sm:text-5xl font-bold mb-12">
          We Don't Just Provide You The Best{' '}
          <span className="text-purple-600">
            Pre-vetted Culturally Fit Developers
          </span>
          ...{' '}
          <span className="text-purple-600 font-bold underline">
            But..
          </span>
        </h2>
      </div>
    </div>
  );
};

export default TalentIntroSection;
