import React from 'react';

const CompetitorTopBar = () => {
  return (
    <div className="consultation-top-bar py-16 px-4 sm:px-6 lg:px-8 bg-white text-center">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold mb-4">
         Most Offshore Development Companies Don't Tell You This<br />
          Let us <span className="text-purple-600">Transform your team for free!</span>
        </h2>
        <p className="text-xl text-gray-600">
Cultural Problems are the #1 Killer of Remote Teams,        </p>
      </div>
    </div>
  );
};

export default CompetitorTopBar;
