import React from 'react';
import Header from './components/Header/Header';
import HeroSection from './components/HeroSection/HeroSection';
import ExpertsBanner from './components/ExpertsBanner/ExpertBanner';
import TalentIntroSection from './components/TalentSection/TalentIntroSection';
import DeveloperProfiles from './components//TalentSection/DeveloperProfiles';
import SolutionImage from './components/KAIZENAI/SolutionImage';
import MidPageCTA from './components/MidPageCTA/MidPageCTA';
import ConsultationTopBar from './components/Consultation/ConsultationTopBar';
import ConsultationSection from './components/Consultation/ConsultationSection';
import Background from './components/Background/Background';
import CompetitorTopBar from './components/CompetitorSection/CompetitorTopBar';
import CompetitorTable from './components/CompetitorSection/CompetitorTable';
import ProblemSection from './components/KAIZENAI/ProblemsSection';
import LastCTA from './components/LastCTA/LastCTA';
import './App.css';
import DeadAvatar from './components/DeadAvatar/DeadAvatar';

function App() {
  return (
    <div className="overflow-x-hidden">
      <Background>
        <Header />
        <HeroSection />
        <ExpertsBanner />
        <DeadAvatar />
        <TalentIntroSection />
        <DeveloperProfiles />
        <ProblemSection />
        <SolutionImage />
        <MidPageCTA />
        <ConsultationTopBar />
        <ConsultationSection />
        <CompetitorTopBar/>
        <CompetitorTable />
        <LastCTA />
      </Background>
    </div>
  );
}

export default App;
