import React from 'react';

const ConsultationTopBar = () => {
  return (
    <div className="consultation-top-bar py-16 px-4 sm:px-6 lg:px-8 bg-white text-center">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold mb-4">
         We're Not Here To Force You To Buy Anything<br />
          We Want To See <span className="text-purple-600"> Your Company Thrive - For Free!</span>
        </h2>
        <p className="text-xl text-gray-600">
We Believe In <span className="text-purple-600">Giving Free Value</span>, NOT Forcing You To Buy Anything      </p>

      </div>
    </div>
  );
};

export default ConsultationTopBar;
