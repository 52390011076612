import React from 'react';

const problems = [
  { icon: '🎯', text: 'Missing Deadlines' },
  { icon: '💸', text: 'Wasting Money' },
  { icon: '📉', text: 'Falling Behind Competitors' },
  { icon: '🛠️', text: 'Lack Of Transparency' },
  { icon: '📉', text: 'Cultural Problems' },
  { icon: '⚙️', text: 'Sh*tty Developers' },
  { icon: '💬', text: 'Communication barriers' },
];

const SolutionImage = () => {
  const solutionImage = `${process.env.PUBLIC_URL}/KAIZENAI/Platform-Laptop.png`;

  return (
    <div className="py-24 bg-purple-50">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row items-start">
          <div className="w-full lg:w-5/12 mb-12 lg:mb-0 pl-12 lg:pl-24">
            <h3 className="text-3xl lg:text-4xl font-bold text-purple-600 mb-8">Forget about:</h3>
            <div className="space-y-4 max-w-sm">
              {problems.map((problem, index) => (
                <div 
                  key={index} 
                  className="flex items-center transform transition duration-300 ease-in-out lg:hover:scale-105 lg:hover:shadow-lg"
                >
                  <div 
                    className="w-12 h-12 flex-shrink-0 rounded-lg mr-3 transition duration-300 ease-in-out lg:hover:shadow-md" 
                    style={{ backgroundColor: `hsl(${index * 45}, 70%, 90%)` }}
                  >
                    <span className="text-2xl flex items-center justify-center h-full">{problem.icon}</span>
                  </div>
                  <div className="bg-white p-3 rounded-lg shadow-md flex-grow transition duration-300 ease-in-out lg:hover:shadow-lg">
                    <p className="text-sm lg:text-base text-gray-700">{problem.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full lg:w-7/12">
            <img 
              src={solutionImage} 
              alt="Solution" 
              className="w-full h-auto" 
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionImage;