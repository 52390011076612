import React from 'react';

const HeroSection = () => (
  <div className="hero-section bg-purple-50 text-center">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 md:py-20">
      <div className="bg-purple-100 rounded-full px-3 py-1 sm:px-4 sm:py-2 inline-flex items-center mb-8 sm:mb-12">
        <span className="text-red-500 mr-2 text-base sm:text-lg" role="img" aria-label="Target">🎯</span>
        <span className="text-red-800 text-sm sm:text-base font-medium">Don't Be Fooled By Software Development Companies!</span>
      </div>
      <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-8 sm:mb-12 leading-tight">
        <span className="text-purple-600">Finding Great Developers</span> Is Hard<br />
        <span className="text-purple-600 mt-4 sm:mt-6 inline-block">Managing Them</span> Is Even Harder<br />

        <span className="mt-8 sm:mt-10 inline-block text-2xl sm:text-3xl underline">We Handle All Of That For You.</span>
      </h1>
     {/*   <p className="text-gray-600 text-base sm:text-lg md:text-xl mb-10 sm:mb-14 max-w-3xl mx-auto">
       We provide Top-Tier Developers & Help You Manage Them Easily, So You Can Focus On Your business. 
      </p> */}
      <a 
        href="https://calendly.com/hamzah-kaizenai/45min" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="inline-flex bg-purple-600 text-white px-6 sm:px-8 md:px-10 py-3 sm:py-4 rounded-full hover:bg-purple-700 transition duration-300 ease-in-out items-center justify-center text-sm sm:text-base md:text-lg font-medium"
      >
        <svg className="w-4 h-4 sm:w-5 sm:h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
        </svg>
        Book Your Free Consultation
      </a>
    </div>
  </div>
);

export default HeroSection;
