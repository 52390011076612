import React from 'react';

const Header = () => (
  <header className="bg-purple-50 py-4">
    <div className="container mx-auto px-6">
      <div className="bg-gradient-to-r from-white via-purple-100 to-white rounded-full shadow-lg py-4 px-8 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-purple-800">KAIZEN AI</h1>
        <nav className="hidden md:flex space-x-8">
        </nav>
        <div className="flex items-center space-x-4">
          <a 
            href="https://calendly.com/hamzah-kaizenai/45min" 
            target="_blank" 
            rel="noopener noreferrer"
            className="bg-purple-600 text-white px-6 py-2 rounded-full hover:bg-purple-700 transition duration-300 ease-in-out"
          >
            Get Started
          </a>
       {/*    <a href="#" className="text-gray-600 hover:text-gray-900">Login</a> */}
        </div>
      </div>
    </div>
  </header>
);

export default Header;
