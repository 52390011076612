import React from 'react';

const CompetitorTable = () => {
  return (
    <div className="flex justify-center py-8">
      <img 
        src={`${process.env.PUBLIC_URL}/Competitors/competitor-table.png`} 
        alt="Competitor Comparison Table" 
        className="max-w-full h-auto"
      />
    </div>
  );
};

export default CompetitorTable;
