import React from 'react';

const steps = [
  {
    number: '01',
    title: 'KAIZEN AI FREE TRIAL',
    subtitle: 'AI Team Performance Analysis',
    description: 'Got A Team Or Looking For One? Either Way - See The Transformation In Real Time.',
  },
  {
    number: '02',
    title: 'CVs Of The Best Offshore Developers',
    subtitle: 'Within 72 Hours',
    description: 'GET EARLY ACCESS to the A.I. Cultural Commander. See it eliminate miscommunication, align teams, and massively boost productivity—effortlessly!',
  },
  {
    number: '03',
    title: '12 Week Transformation Blueprint',
    description: 'Get a laser-focused action plan designed for rapid results. Tailored steps to skyrocket your team\'s efficiency and productivity immediately. No fluff, just results!',
  },
  {
    number: '04',
    title: 'INSTANT Action Plan',
    description: 'Get a turbocharged action plan that drives immediate results. Supercharge your team\'s productivity and efficiency with tailored strategies!',
  },
];

const ConsultationSection = () => {
  return (
    <div className="consultation-section py-16 px-4 sm:px-6 lg:px-8 bg-purple-50">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl font-bold text-purple-700 mb-8">Looking For A Developer + 10x Management?</h2>
        
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            {index === 2 && (
              <h2 className="text-2xl font-bold text-purple-700 mb-8 mt-12">Trouble With Management?</h2>
            )}
            <div className="mb-8 p-6 bg-white rounded-lg shadow-md">
              <div className="flex items-center">
                <div className="text-purple-300 text-4xl font-bold mr-6">{step.number}</div>
                <div>
                  <h3 className="text-xl font-bold text-gray-900">
                    {step.title} <span className="text-purple-600">{step.subtitle}</span>
                  </h3>
                  <p className="text-gray-600">{step.description}</p>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}

        <div className="consultation-cta bg-gradient-to-r from-purple-600 to-purple-800 hover:from-purple-500 hover:to-purple-700 text-white py-8 px-4 sm:px-6 lg:px-8 rounded-lg shadow-md text-center mt-16 transition duration-300 group">
          <div className="flex items-center justify-center">
            <span className="text-4xl mr-4">🚀</span>
            <a
              href="https://calendly.com/hamzah-kaizenai/45min"
              target="_blank"
              rel="noopener noreferrer"
              className="text-2xl lg:text-3xl font-bold text-white px-6 py-3 rounded-md bg-opacity-50 hover:bg-opacity-70 transition duration-300"
            >
              I'm Ready To Transform
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultationSection;