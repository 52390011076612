import React from 'react';

const MidPageCTA = () => {
  return (
    <div className="bg-purple-600 text-white py-8 px-4 sm:px-6 lg:px-8 rounded-lg shadow-md my-16">
      <div className="max-w-7xl mx-auto flex flex-col items-center text-center">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-6 leading-tight">
          Scaling Your Business Is Hard Enough,<br className="hidden sm:inline" /> Don't Make It Harder!
        </h2>
        <a
          href="https://calendly.com/hamzah-kaizenai/45min"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-purple-600 bg-white hover:bg-gray-100 transition duration-300 w-full sm:w-auto justify-center"
        >
          <span className="mr-2">📞</span> Book a Free Consultation
        </a>
      </div>
    </div>
  );
};

export default MidPageCTA;