import React from 'react';

const DeveloperCard = ({ name, role, languages, skills, seniority, experience, country, imageFileName }) => {
  return (
    <div 
      className="bg-white rounded-lg shadow-md p-6 transition-all duration-300 ease-in-out hover:-translate-y-2 hover:shadow-lg"
    >
      <img 
        src={`${process.env.PUBLIC_URL}/DeveloperProfiles/${imageFileName}`} 
        alt={name} 
        className="w-24 h-24 rounded-full mx-auto mb-4 object-cover transition-all duration-300 hover:scale-110"
      />
      <h3 className="text-xl font-semibold mb-1">{name}</h3>
      <p className="text-gray-600 mb-4">{role}</p>
      <div className="mb-2">
        <span className="font-semibold">{languages ? 'Languages:' : 'Skills:'}</span> {languages ? languages.join(', ') : skills.join(', ')}
      </div>
      <div className="mb-2">
        <span className="font-semibold">Seniority:</span> {seniority}
      </div>
      <div className="mb-2">
        <span className="font-semibold">Experience:</span> {experience}
      </div>
      <div className="mb-4">
        <span className="font-semibold">Country:</span> {country}
      </div>
      <a
        href="https://calendly.com/hamzah-kaizenai/45min"
        target="_blank"
        rel="noopener noreferrer"
        className="block w-full text-center text-white py-2 rounded-md transition duration-300 bg-purple-600 hover:bg-purple-700"
      >
        Hire Me
      </a>
    </div>
  );
};

const DeveloperProfiles = () => {
  const developers = [
    { 
      name: 'Abdul Samad', 
      role: 'Front-End Developer', 
      languages: ['JavaScript', 'React', 'Vue.js'],
      seniority: 'Senior', 
      experience: '7 Years', 
      country: 'Pakistan',
      imageFileName: 'Abdul.jpg'
    },
    { 
      name: 'Dmitriy Slobodskoi', 
      role: 'Back-End Developer', 
      languages: ['Python', 'Node.js', 'Go', 'Ruby'],
      seniority: 'Senior', 
      experience: '8 Years', 
      country: 'Ukraine',
      imageFileName: 'Tommy.jpg'
    },
    { 
      name: 'Jacob Nielsen', 
      role: 'Full-Stack Developer', 
      languages: ['JavaScript', 'React', 'Node.js', 'MongoDB'],
      seniority: 'Senior', 
      experience: '9 Years', 
      country: 'Poland',
      imageFileName: 'Jacob.jpg'
    },
    { 
      name: 'Abhishek Kumar', 
      role: 'Machine Learning Engineer', 
      skills: ['Python', 'TensorFlow', 'PyTorch', 'Scikit-learn'],
      seniority: 'Senior', 
      experience: '6 Years', 
      country: 'India',
      imageFileName: 'Abishek.jpeg'
    },
  ];

  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h3 className="text-3xl font-semibold mb-8 text-center text-gray-800">
          (Example Of Some Of Our Pre-Vetted Kickass Talents)
        </h3>
        <div className="overflow-x-auto pb-4 -mx-4 px-4 sm:mx-0 sm:px-0">
          <div className="inline-flex space-x-4 sm:grid sm:grid-cols-2 sm:gap-8 lg:grid-cols-4">
            {developers.map((dev, index) => (
              <div key={index} className="w-64 sm:w-auto flex-shrink-0">
                <DeveloperCard {...dev} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeveloperProfiles;
