import React from 'react';

const LastCTA = () => {
  return (
    <div className="bg-purple-600 text-white py-16 px-4 sm:px-6 lg:px-8 text-center">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold mb-4">
          Free Strategy Session: Learn How We Help Founders Scale Fast, Effortlessly!
        </h2>
        <p className="text-xl mb-8">
          Unlock Proven Strategies To Maximize Your Team’s Efficiency WITHOUT Any Extra Work Or Time Investment
        </p>
        <a
          href="https://calendly.com/hamzah-kaizenai/45min"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-purple-600 bg-white hover:bg-gray-100"
        >
          Revolutionize Your Team Management
        </a>
      </div>
    </div>
  );
};

export default LastCTA;
